
.details {
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
}

.details--expanded {
  opacity: 1;
}

.takemehome-modus .details {
  display: none;
}

.details--expanded-small {

}

.details > section {
  padding: 1rem;
}

.details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.25rem 0;
}

.details__title {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.details__category {
  font-weight: 300;
  text-transform: capitalize;
  margin-left: 0.25rem;
}

.details__close {
  border: 0;
  background: transparent;
}

.details__close svg {
  display: block;
  height: 1rem;
  width: 1rem;
  fill: var(--beige-900);
}

.details__desc {
  margin: 0 0 0 0;
  font-size:  1rem;
}

.details__googlemaps {
  display: none;
  text-decoration: none;
}