
.mapmarker {
  width: 32px;
  height: 32px;
  stroke: #fff;
  stroke-width: 2;
}

.mapmarker2 {
  width: 32px;
  height: 32px;
  stroke: #fff;
  stroke-width: 2;
}

.mapmarker3 {
  width: 28px;
  height: 28px;
  fill: var(--beige-700);
  stroke: #fff;
  stroke-width: 1;
}

.takemehome-modus .mapmarker3 {
  fill: var(--purple-500);
}

.liverbird {
  width: 28px;
  height: 28px;
  fill: #D91920;
}


.selectedmarker {
  font-size: 0.75rem;
  position: absolute;
  font-weight: 500;
  white-space: nowrap;
  padding: 0 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 4px rgba(255, 255, 255, 1);
  top: 0.5rem;
  left: 1.75rem;
}

.locationCircle {
    fill: var(--purple-100);;
    stroke: var(--purple-500);;
}