

.list {
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}

.list--expanded {
  max-height: 33vh;
  overflow: scroll;
}

.list__top {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  z-index: 11;
  height: 1.125rem;
  pointer-events: none;
  margin-bottom: -1.125rem;
}


.list--expanded-small {
}

.list__listitem {
  
}

.list__listitem:first-child {
  margin-top: 1.125rem;
}
.list__listitem:last-child {
  margin-bottom: 1.125rem;
}

.list__link {
  align-items: center;
  color: var(--beige-900);
  justify-content: flex-start;
  display: flex;
  line-height: 1.5rem;
  padding: 0.375rem 1.5rem;
  border: 0;
  width: 100%;
  background: transparent;
  font-size:  1rem;
}

.list__link--active {
  font-weight: 400;
}

.list__link svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.list__category {
}

.list__link.pub svg {
  fill: var(--red-500);
}

.list__link.mat svg {
  fill: var(--blue-500);
}

.list__link.ymse svg {
  fill: var(--green-500);
}

.list__link--active.pub {
  background-color: var(--red-100);
}

.list__link--active.mat {
  background-color: var(--blue-100);
}

.list__link--active.ymse {
  background-color: var(--green-100);
}






