html {
  --red-100: hsl(13, 54%, 97%);
  --red-500: hsl(13, 99%, 63%);
  --beige-100: hsl(43, 22%, 95%);
  --beige-500: hsl(43, 29%, 54%);
  --beige-700: hsl(43, 37%, 32%);
  --beige-900: hsl(43, 90%, 12%);
  --green-100: hsl(103, 35%, 97%);
  --green-500: hsl(103, 79%, 32%);
  --blue-100: hsl(193, 32%, 96%);
  --blue-500: hsl(193, 100%, 25%);
  --purple-100: hsl(283, 100%, 96%);
  --purple-500: hsl(283, 69%, 65%);
}

body {
  color: var(--beige-900);
}

strong {
  font-weight: 400;
}

a {
  color: var(--beige-900);
  text-decoration: none;
}

main {
  display: block;
  height: 100%;
}

.mapcontent {
  height: calc(100% - 3.5rem);
}

.mapoverlays {
  position: fixed;
  bottom: 3.5rem;
  left: 0;
  z-index: 10;
  width: 100%;
}


#mapcontainer {
  height: 100%;
  z-index: 1;
}

.content {
  height: 3.5rem;
}


