
.navigation {
  background-color: #fff;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.navigation__listitem {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.takemehome-modus .navigation > :not(.navigation__listitem--takemehome) {
  opacity: 0.5;
  pointer-events: none;
}


.navigation__listitem--listtoggle {
  flex-grow: 0;
}

.navigation__listitem--locatebutton {
  flex-grow: 0;
}
.navigation__listitem--takemehome {
  flex-grow: 0;
}



.navigation__link {
  background-color: #fff;
  border: 0;
  border-radius: 0.25rem;
  display: block;
  font-weight: 400;
  font-size:  1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  text-align: center;
}

/*
.navigation__link:hover {
  background-color: var(--beige-100);
}
*/

.navigation__link--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navigation__link--icon svg {
  margin-right: 0.5rem;
  display: block;
  height: 1rem;
  width: 1rem;
}


.navigation__link--selected {
  background-color: var(--beige-100);
}

.navigation__link--selected svg {

}


.navigation__link--listtoggle {
  background-color: transparent;
  padding: 0.75rem 1rem;
}

.navigation__link--listtoggle svg {
  display: block;
  fill: var(--beige-900);
  height: 1rem;
  width: 1rem;
}

.navigation__link--listtoggle--active {

}

.homebutton {
  background-color: #fff;
  border: 0;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.locatebutton {
  background-color: #fff;
  border: 0;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.homebutton,
.locatebutton:focus {
  outline: none;
}


.locatebutton--searching {
  background-color: var(--purple-100);
}

.homebutton svg,
.locatebutton svg {
  fill: var(--beige-900);
  height: 1rem;
  width: 1rem;
}

.homebutton--takemehome svg {
  fill: var(--purple-500);
}

.locatebutton--searching svg {
    animation: pulse 1.5s ease-in-out infinite alternate;
}

@keyframes pulse {
  0% {
    fill: var(--beige-900);
  }
  50% {
    fill: var(--purple-500);
  }
  100% {
    fill: var(--beige-900);
  }
}



.distanceFromHome {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 0;
  overflow: hidden;
}

.takemehome-modus .distanceFromHome {
  height: auto;
  overflow: auto;
}

.distanceFromHome p {
  font-size: 1.125rem;
  margin: 0;
  padding: 1rem;
  text-align: center;
}


.navigation__link--pub svg,
.pub svg {
  fill: var(--red-500);
}
.navigation__link--mat svg,
.mat svg {
  fill: var(--blue-500);
}
.navigation__link--ymse svg,
.ymse svg {
  fill: var(--green-500);
}

.pub {
  color: var(--red-500);
}

.mat {
  color: var(--blue-500);
}

.ymse {
  color: var(--green-500);
}